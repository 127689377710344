import {
    Col,
    Input, PageHeader, Row, Space, Typography, Select, Button,
} from 'antd';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { WebinarsContext } from './context/webinars.context';
import Table from './components/Table';
import DrawerWebinar from '../../../widgets/Drawers/Webinar';

interface Props {
    params: any;
}

const { Search } = Input;
const { Option } = Select;

const Webinars = ({ params }: Props) => {
    const { t } = useTranslation('admin');

    const navigate = useNavigate();

    const {
        loading,
        total,
        paginator,
        data,
        onPageChange,
        fetchData,
        searchParams,
        handleSearch,
        setItemSelected,
        itemSelected,
        categories,
        handleCategory,
    } = useContext(WebinarsContext);

    const { limit, page } = paginator;
    const { search, categoryID } = searchParams;

    const defaultSearch = params.get('search');
    const defaultCategory = params.get('categoryID');

    useEffect(() => {
        fetchData();
    }, [paginator, search, defaultSearch, categoryID, defaultCategory]);

    return (
        <div className="articles__edit">
            {
                itemSelected && (
                    <DrawerWebinar
                        webinar={itemSelected}
                        open={!!itemSelected}
                        onClose={() => setItemSelected(null)}
                    />
                )

            }
            <PageHeader
                className="site-page-header-responsive"
                title={t('ADMIN_WEBINARS')}
                extra={[
                    <Button
                        key="1"
                        type="primary"
                        onClick={() => navigate('/admin/webinars/new-webinar')}
                    >
                        {t('ADD')}
                    </Button>,
                ]}
                footer={(
                    <Space direction="vertical" size={16} className="full__width">
                        <Row className="full__width" gutter={[24, 32]}>
                            <Col xs={24} lg={20}>
                                <Search
                                    allowClear
                                    enterButton
                                    placeholder={t('TABLE.SEARCH_WEBINAR')}
                                    onSearch={handleSearch}
                                    defaultValue={search || defaultSearch}
                                    disabled={loading}
                                />
                            </Col>
                            <Col xs={24} lg={4}>
                                <Select
                                    placeholder={t('TABLE.QUOTE_TYPE')}
                                    className="full__width"
                                    defaultValue={defaultCategory}
                                    allowClear
                                    onChange={(value) => {
                                        if (value === undefined) value = '';
                                        handleCategory(value);
                                    }}
                                >
                                    {
                                        categories.map((category, index) => (
                                            <Option
                                                value={category._id}
                                                key={index}
                                            >
                                                {category.name}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Col>
                        </Row>
                        <Table
                            loading={loading}
                            total={total}
                            data={data}
                            onPageChange={onPageChange}
                            limit={limit}
                            page={page}
                            setItemSelected={setItemSelected}
                        />
                    </Space>
                )}
            >
                <Typography.Paragraph>
                    {t('ADMIN_WEBINARS_DESCRIPTION')}
                </Typography.Paragraph>
            </PageHeader>
        </div>
    );
};

export default Webinars;
